html, body, #root {
  height: 100%;
}

.stand-layer-icon {
  cursor: default;
  pointer-events: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    white-space: nowrap;
    text-align: center;
    text-shadow:
            -1px -1px 0 white,
            1px -1px 0 white,
            -1px 1px 0 white,
            1px 1px 0 white;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}
